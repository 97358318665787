<template>
    <div class="zt-page-content" ref="load">
        <div v-if="item" class="container">
            <div class="detail-top">
                <div class="top flex">
                    <div class="info-img">
                        <img src="@/assets/images/desgin/icon_design.png">
                    </div>
                    <div class="detail-info flex-1">
                        <div class="info-name ellipsis1">
                            <b>{{item.name}}</b>
                        </div>
                        <div class="info-design_idea">
                            设计理念：{{item.design_idea || '暂无设计理念'}}
                        </div>
                        <div v-if="item.dimension" class="info-spec flex">
                            设计规格：
                            <span v-if="item.dimension && item.dimension.length>0" class="flex">
                                <label v-for="(spec,idx) in item.dimension" :key="idx">{{spec}}</label>
                            </span>
                            <span v-else>暂无设计规格</span>
                        </div>
                        <div class="info-desc">
                            作品描述：{{item.intro || '暂无作品概述'}}
                        </div>
                    </div>
                </div>
                <div class="bottom flex">
                    <div v-if="item.style" class="flex flex-align-center">
                        <img src="@/assets/images/desgin/icon_style.png">
                        <span>{{item.style.name}}</span>
                    </div>
                    <div v-if="item.cat" class="flex flex-align-center">
                        <img src="@/assets/images/desgin/icon_cat.png">
                        <span>{{item.cat.name}}</span>
                    </div>
                </div>
            </div>
            <div v-if="!setType" class="detail-middle">
                <div class="title">销售信息</div>
                <div class="dm-box flex">
                    <div>
                        <div>销售方式：
                            <span v-if="item.price_type==0">设计版权出售</span>
                            <span v-if="item.price_type==1">分成合作</span>
                            <span v-if="item.price_type==3">需求定制</span>
                            <span v-if="item.price_type==4">暂不上架</span>
                        </div>
                        <div>一口价：
                            <span class="price">￥{{item.price | priceFilt}}</span>
                        </div>
                    </div>
                    <div>
                        <div>当前状态：
                            <span v-if="item.stat==1">已上架</span>
                            <span v-if="item.stat==2">未上架</span>
                            <span v-if="item.stat==3">已删除</span>
                            <span v-if="item.stat==4">已加入回收站</span>
                            <span v-if="item.stat==5">
                                <span v-if="item.trans_stat>=6">交易完成</span>
                                <span v-else>交易中</span>
                            </span>
                        </div>
                        <div v-if="item.price_type==0">合同：
                            <el-button @click="contract_view(item)" size="small" type="text">点击查看</el-button>
                        </div>
                    </div>
                    <div>
                        <div>最后修改时间：
                            <span>{{item.updated_at | moment("YYYY/MM/DD")}}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="detail-bottom">
                <div class="tab_menu flex">
                    <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="menu_item" :class="{'cur':tabIndex == index}">
                        {{item.name}}
                    </div>
                </div>
                <div class="db-box">
                    <div v-if="tabIndex==0">
                        <div v-if="item.photo_render && item.photo_render.length>0">
                            <img v-for="(item,idx) in item.photo_render" :key="idx" 
                                :src="(IMG_URL_PRE || '')+item+'!width_800px'" alt="">
                        </div>
                        <div v-else>
                            <img src="@/assets/images/desgin/zwt.png">
                        </div>
                    </div>
                    <div v-if="tabIndex==1">
                        <div v-if="item.photo_size && item.photo_size.length>0">
                            <img v-for="(item,idx) in item.photo_size" :key="idx" 
                                :src="(IMG_URL_PRE || '')+item+'!width_800px'" alt="">
                        </div>
                        <div v-else>
                            <img src="@/assets/images/desgin/zwt.png">
                        </div>
                    </div>
                    <div v-if="tabIndex==2">
                        <div v-if="item.photo_story && item.photo_story.length>0">
                            <img v-for="(item,idx) in item.photo_story" :key="idx" 
                                :src="(IMG_URL_PRE || '')+item+'!width_800px'" alt="">
                        </div>
                        <div v-else>
                            <img src="@/assets/images/desgin/zwt.png">
                        </div>
                    </div>
                </div>
                <div v-if="item &&item.photo_cad" class="db-cad flex flex-align-center">
                    <div style="margin-right:10px">设计CAD文件：</div>
                    <div class="flex-1 flex flex-align-center">
                        <img src="@/assets/images/desgin/icon_file.png">
                        <div v-if="item && item.photo_cad" class="text">（共{{item.photo_cad.length}}份）</div>
                        <div style="margin-left: 20px;">
                            <a v-if="item && item.photo_cad.length>0" :href="IMG_URL_PRE+item.photo_cad[0]">
                                <el-button @click="download()" size="mini" type="primary" round>下载</el-button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { IMG_URL_PRE,STATIC_URL_PRE } from '@/config';
import { contractView, testHt } from '@/service/contract';
import { singleDetail } from '@/service/design';
export default {
    data() {
        return {
            IMG_URL_PRE,
            STATIC_URL_PRE,
            item: null,
            tabList:[
                { name:"3D单品渲染图" },
                { name:"标高图" },
                { name:"素材图" },
            ],
            tabIndex:0,
            setType: "", // 1从套系跳转进来
        }
    },
    created() {
        this.setType = this.$route.query.setType;
        this.refreshItem();
    },
    mounted() {},
    methods: {
        // 选项卡
        tab(index) {
            this.tabIndex = index;
            console.log(index);
        },
        refreshItem() { // 获取数据
            console.log('refreshItem');
            const _loading = this.$loading({
                lock: true,
                text: '拼命加载中......',
                target: this.$refs.load
            });
            singleDetail(this.$route.params.id).then(res => {
                this.item = res
                console.log(this.item);
                _loading.close();
            }).catch(err => {
                console.log(err);
                this.$message.error(err.message);
                _loading.close();
            });
        },
        contract_view(item) { // 查看合同
            if (item.contract_no) {
                window.open(contractView(item.contract_no));
            } else {
                let obj = {
                    contract_title:"设计版权购买合同",
                    content_file: item.contract_file,
                    custom_cover_page: item.custom_cover_page,
                }
                if (item.price_type==0) {
                    obj.contract_title = "设计版权购买合同";
                } else if (item.price_type==3) {
                    obj.contract_title = "需求定制合同";
                } else {
                    obj.contract_title = "合同标题";
                }
                if (obj.content_file) {
                    window.open(testHt(obj));
                }
            }
        },
    }
}
</script>
<style scoped src="../../style.css"></style>
<style scoped lang="less">
.container {
    width: 1200px;
    margin: 0 auto;
}
.detail-top {
    padding: 20px;
    background: #fff;
    border: 1px solid #E6E6E6;
    margin-bottom: 20px;
    .top {
        .info-img {
            width: 36px;
            height: 36px;
            background: #5074EE;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 12px;
            img {
                width: 100%;
                height: 100%;
            }
        }
        .info-name {
            font-size: 18px;
            color: #292929;
            line-height: 25px;
            margin-bottom: 12px;
        }
        .info-design_idea {
            font-size: 14px;
            color: #4D4D4D;
            line-height: 20px;
        }
        .info-spec {
            padding: 16px 0 4px;
            font-size: 14px;
            color: #292929;
            line-height: 26px;
            span {
                font-size: 14px;
                color: #B3B3B3;
                label {
                    padding: 4px 8px;
                    border: 1px solid #CCCCCC;
                    font-size: 12px;
                    color: #808080;
                    line-height: 16px;
                    margin: 0 12px 12px 0;
                }
            }
        }
        .info-desc {
            padding: 12px;
            background: #FAFAFA;
            border: 1px solid #E6E6E6;
            margin-bottom: 20px;
        }
    }
    .bottom {
        padding: 20px 50px 0;
        border-top: 1px solid #E6E6E6;
        >div {
            font-size: 14px;
            color: #4D4D4D;
            margin-right: 45px;
            >img {
                height: 30px;
                margin-right: 12px;
            }
        }
    }
}
.detail-middle {
    padding: 20px;
    background: #fff;
    border: 1px solid #E6E6E6;
    margin-bottom: 20px;
    .title {
        font-size: 18px;
        color: #292929;
        line-height: 25px;
        margin-bottom: 14px;
    }
    .dm-box {
        >div {
            font-size: 14px;
            color: #666666;
            margin-right: 60px;
            >div {
                height: 32px;
                line-height: 32px;
                >span {
                    color: #4D4D4D;
                }
                &:first-child {
                    margin-bottom: 5px;
                }
            }
        }
        .price {
            font-size: 18px;
            color: #F66F6A;
        }
    }
}
.detail-bottom {
    background: #fff;
    border: 1px solid #E6E6E6;
    margin-bottom: 40px;
    .tab_menu {
        padding: 0 20px;
        height: 50px;
        border-bottom: 1px solid #E6E6E6;
        .menu_item {
            height: 50px;
            line-height: 50px;
            margin-right: 40px;
            cursor: pointer;
            font-size: 16px;
            font-weight: 500;
            color: #4D4D4D;
            &.cur {
                color: #5074EE;
                border-bottom: 2px solid #5074EE;
            }
        }
    }
    .db-box {
        width: 750px;
        margin: 0 auto;
        padding: 40px 0 20px;
        img {
            width: 100%;
            margin-bottom: 12px;
        }
    }
    .db-cad {
        padding: 20px;
        border-top: 1px solid #E6E6E6;
        font-size: 14px;
        color: #808080;
        img {
            width: 26px;
            height: 22px;
        }
        .text {
            font-size: 12px;
            color: #B3B3B3;
        }
    }
}
</style>